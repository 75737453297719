import { Component, Input, inject } from '@angular/core';
import { FileService } from '@core/services/file.service';

@Component({
  selector: 'app-reservation-card',
  templateUrl: './reservation-card.component.html',
  styleUrls: ['./reservation-card.component.scss'],
})
export class ReservationCardComponent {
  @Input() tourAvatar = '';
  @Input() tourName = '';
  @Input() packageOption: any = [];
  @Input() comboOption: any = [];
  fileService = inject(FileService);

  getImage(path: string): string {
    let result = '';
    if (path.includes('http')) {
      result = path;
    } else {
      result = this.fileService.getImgWebp(path);
    }
    return result;
  }
}
