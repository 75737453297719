import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/layouts/admin-layout/admin-layout.component';
import { AppRoutes } from '@core/enum/routes.enum';
import { AuthGuard } from '@core/guards/auth.guard';

const routes: Routes = [
  {
    path: AppRoutes.LOGIN,
    title: 'Login',
    loadComponent: () =>
      import('./modules/login/login.component').then(
        ({ LoginComponent }) => LoginComponent,
      ),
  },
  {
    path: `${AppRoutes.BOOKING_HELPDESK}/:id`,
    loadChildren: () =>
      import(
        './features/booking-helpdesk-details/booking-helpdesk-detail.module'
      ).then((m) => m.BookingHelpdeskDetailModule),
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then(
            (m) => m.DashboadModule,
          ),
      },
      {
        path: AppRoutes.BRANCH_MANAGEMENT,
        loadChildren: () =>
          import(
            './features/branches-management/branches-management.module'
          ).then((module) => module.BranchesManagementModule),
      },
      {
        path: AppRoutes.BOOKING_HELPDESK,
        loadChildren: () =>
          import('./features/booking-helpdesk/booking-helpdesk.module').then(
            (module) => module.BookingHelpdeskModule,
          ),
      },
      {
        path: AppRoutes.REFUND_MANAGEMENT,
        loadChildren: () =>
          import('./features/refund-management/refund-management.module').then(
            (module) => module.RefundManagementModule,
          ),
      },
      {
        path: AppRoutes.VOUCHER_MANAGEMENT,
        loadChildren: () =>
          import(
            './features/voucher-management/voucher-management.module'
          ).then((module) => module.VoucherManagementModule),
      },
      {
        path: AppRoutes.BANNER_MANAGERMENT,
        loadChildren: () =>
          import('./features/banner-management/banner-management.module').then(
            (module) => module.BannerManagementModule,
          ),
      },
      {
        path: AppRoutes.ADMIN_SUPPORT,
        loadChildren: () =>
          import('./features/admin-support/admin-support.module').then(
            (module) => module.AdminSupportModule,
          ),
      },
      {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
    // canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
