import { AppRoutes } from '@core/enum/routes.enum';
import { NavigationItem } from '../models/interfaces/navigation';
import { SoctripIcons } from './soctrip-icon.enum';

export const MenuSidebar: NavigationItem[] = [
  {
    path: 'dashboard',
    title: 'dashboard',
    icon: SoctripIcons.TARGET_04,
  },
  {
    path: AppRoutes.BRANCH_MANAGEMENT,
    title: AppRoutes.BRANCH_MANAGEMENT,
    icon: SoctripIcons.BUILDING_02,
  },
  {
    path: AppRoutes.BOOKING_HELPDESK,
    title: AppRoutes.BOOKING_HELPDESK,
    icon: SoctripIcons.FILE_SEARCH_02,
  },
  {
    path: AppRoutes.REFUND_MANAGEMENT,
    title: AppRoutes.REFUND_MANAGEMENT,
    icon: SoctripIcons.CREDIT_CARD_REFRESH,
  },
  {
    path: AppRoutes.VOUCHER_MANAGEMENT,
    title: AppRoutes.VOUCHER_MANAGEMENT,
    icon: SoctripIcons.TICKET_01,
  },
  {
    path: AppRoutes.BANNER_MANAGERMENT,
    title: AppRoutes.BANNER_MANAGERMENT,
    icon: SoctripIcons.IMAGE_INDENT_LEFT,
  },
];
