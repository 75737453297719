import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss'],
})
export class BookingDetailsComponent {
  @Input() departureDate = '';
  @Input() endDate = '';
  @Input() adultNumber = 0;
  @Input() childNumber = 0;
  @Input() infantNumber = 0;
  @Input() duration = '';
  @Input() durationUnit = '';
  @Input() timezone = '';
  prefixTranslation =
    'section-booking-helpdesk.booking-helpdesk-detail.booking-details.';

  @Output() viewGuestListEvent = new EventEmitter();

  viewGuestList(): void {
    this.viewGuestListEvent.emit();
  }

  formatDate(date: string): string {
    return moment(new Date(date)).format('MM/DD/YYYY');
  }
}
