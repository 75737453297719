export enum AppRoutes {
  HOME = 'home',
  BOOKING_HELPDESK = 'booking-helpdesk',
  MY_BOOKING = 'my-bookings',
  BRANCH_MANAGEMENT = 'branch-management',
  REFUND_MANAGEMENT = 'refund-management',
  REFUND_HUB = 'refund-hub',
  TOUR = 'TOUR',
  TRAVEL_TOUR = 'TRAVEL_TOUR',
  VOUCHER_MODULE = 'voucher-module',
  VOUCHER_MANAGEMENT = 'voucher-management',
  BANNER_MANAGERMENT = 'banner-management',
  LOGIN = 'login',
  ADMIN_SUPPORT = 'admin-support',
}
